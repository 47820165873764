var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"project-header-row",attrs:{"no-gutters":""}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.invoiceTitle)+" ")])],1),(_vm.invoice && _vm.invoice.path)?_c('v-card',{staticClass:"pl-3 pt-3 pb-3",attrs:{"flat":""}},[_c('v-flex',[_c('v-row',{staticClass:"font-weight-bold",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.$localize('Invoice.addedOn')))]),_c('v-col',{attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.$localize('Invoice.sentOn')))]),_c('v-col',{attrs:{"cols":"4"}})],1),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.$format.shortDate(_vm.invoice.date)))]),_c('v-col',{attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.$format.shortDate(_vm.invoice.sent)))]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-toolbar',{staticStyle:{"background":"none"},attrs:{"flat":"","dense":"","tile":"","height":"28"}},[_c('v-spacer'),_c('v-toolbar-items',[(
                                    _vm.$session.roles.includes(
                                        'invoices_manage'
                                    )
                                )?_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){_vm.sendInvoice = true}}},[_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("mdi-email-send")])],1):_vm._e(),(
                                    _vm.$session.roles.includes('invoices_view')
                                )?_c('v-btn',{attrs:{"x-small":""},on:{"click":_vm.downloadInvoice}},[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-adobe-acrobat")])],1):_vm._e()],1)],1)],1)],1)],1),_c('div',{staticClass:"text-center"},[(_vm.$session.roles.includes('invoices_manage'))?_c('v-btn',{staticClass:"ma-5",on:{"click":function($event){_vm.createInvoice = true}}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$localize('ProjectInvoice.btn.recreateInvoice')))])],1):_vm._e()],1)],1):_c('v-card',{staticClass:"text-center",attrs:{"flat":""}},[(_vm.$session.roles.includes('invoices_manage'))?_c('v-btn',{staticClass:"ma-5",on:{"click":function($event){_vm.createInvoice = true}}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$localize('ProjectInvoice.btn.createInvoice')))])],1):_vm._e()],1),(_vm.createInvoice)?_c('create-invoice',{attrs:{"project-id":_vm.$route.params.projectId},model:{value:(_vm.createInvoice),callback:function ($$v) {_vm.createInvoice=$$v},expression:"createInvoice"}}):_vm._e(),(_vm.sendInvoice)?_c('send-invoice',{attrs:{"project-id":_vm.projectId},model:{value:(_vm.sendInvoice),callback:function ($$v) {_vm.sendInvoice=$$v},expression:"sendInvoice"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }