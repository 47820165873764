<template>
    <div>
    <v-row v-if="updatingCalculation || !calculation.isLatestPublishedVersion">
        <v-col class="text-center">
            <v-alert type="info">
                <div v-if="!updatingCalculation">
                    <p>
                        {{
                            $localize(
                                'CalculatePart.newCalculationVersionAvailable'
                            )
                        }}
                    </p>
                    <v-btn @click="updateCalculation">
                        {{ $localize('form.button.update') }}
                        <v-icon>mdi-update</v-icon>
                    </v-btn>
                </div>
                <div v-else>
                    {{ $localize('CalculatePart.calculationUpdated') }}
                </div>
            </v-alert>
        </v-col>
    </v-row>
    <v-row v-if="updatingArticles || result.oldArticleUsed">
        <v-col class="text-center">
            <v-alert type="info">
                <div v-if="!updatingArticles">
                    <p>
                        {{
                            $localize(
                                'CalculatePart.newArticleVersionsAvailable'
                            )
                        }}
                    </p>
                    <v-btn @click="updateArticles">
                        {{ $localize('form.button.update') }}
                        <v-icon>mdi-update</v-icon>
                    </v-btn>
                </div>
                <div v-else>
                    {{ $localize('CalculatePart.articlesUpdated') }}
                </div>
            </v-alert>
        </v-col>
    </v-row>
    <v-row>
        <v-col class="col-12 col-sm-6">
            <template v-for="inputField in calculation.input">
                <template v-if="inputField.type === 'number'">
                    <number-field
                        :key="inputField.key"
                        :label="inputField.label"
                        :input-field="inputField"
                        v-model="input[inputField.key]"
                        @input="onInput"
                            :dense="dense"
                    />
                </template>
                <template v-if="inputField.type === 'text'">
                    <text-field
                        :key="inputField.key"
                        :label="inputField.label"
                        :input-field="inputField"
                        v-model="input[inputField.key]"
                        @input="onInput"
                            :dense="dense"
                    />
                </template>
                <template v-else-if="inputField.type === 'article'">
                    <article-field
                        :key="inputField.key"
                        :label="inputField.label"
                        :article-calculation-ids="
                            inputField.articleCalculationIds
                        "
                        :input-field="inputField"
                        v-model="input[inputField.key]"
                        @input="onInput"
                        @update:article="
                            setInputArticle(inputField.key, $event)
                        "
                            :dense="dense"
                    />
                </template>
            </template>
        </v-col>
        <v-col class="col-12 col-sm-6">
            <calculation-output
                :state="output"
                :grid="calculation.outputGrid"
                :hide-buy-price="hideBuyPrice"
            />
            <template v-for="inputField in calculation.input">
                <div
                    :key="input[inputField.key]"
                    v-if="
                        inputArticles[inputField.key] &&
                        (inputArticles[inputField.key].note ||
                            inputArticles[inputField.key].url)
                    "
                >
                    <div>
                        <a
                            v-if="inputArticles[inputField.key].url"
                            :href="inputArticles[inputField.key].url"
                            target="_new"
                        >
                            {{ inputArticles[inputField.key].text }}
                        </a>
                        <span v-else class="font-weight-bold">
                            {{ inputArticles[inputField.key].text }}
                        </span>
                    </div>
                    <div v-if="inputArticles[inputField.key].note" class="pre">
                        {{ inputArticles[inputField.key].note }}
                    </div>
                </div>
            </template>
        </v-col>
    </v-row>
    </div>
</template>

<script>
import Vue from 'vue'

import { isGuid } from '@/services/validation'
import { getCalculationById, testCalculation } from '@/services/api'

import NumberField from '@/main/advanced/components/NumberField'
import ArticleField from '@/main/advanced/components/ArticleField'
import TextField from '@/main/advanced/components/TextField'
import CalculationOutput from '@/main/advanced/components/CalculationOutput'

import sendCommand from '@/services/sendCommand'

import { formatISO } from 'date-fns'

const recalculateDelay = 1000

export default {
    components: {
        NumberField,
        ArticleField,
        TextField,
        CalculationOutput,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        part: {
            validator: Object,
            required: true,
        },
        projectId: {
            validator: isGuid,
            required: true,
        },
        hideBuyPrice: {
            type: Boolean,
            required: true
        },
        autoCalculate: {
            type: Boolean,
            required: true
        },
        recalculate:{
            type: Boolean,
            default: false
        },
        dense: Boolean

    },
    data() {
        return {
            loading: false,
            calculating: false,
            updatingCalculation: false,
            updatingArticles: false,
            calculation: {},

            input: this.value,
            inputArticles: {},
            output: {},
            result: {},

            newVersionDate: null,

            isDirty: true,
            updateTimeout: null,
        }
    },
    watch: {
        value: {
            handler(value){
                this.input = value
            }
        },
        part: {
            immediate: true,
            handler(part) {
                if (part) {
                    this.loadCalculation()
                }
            },
        },
        recalculate(recalculate){
            if(recalculate)
                this.test()
        }
    },
    methods: {
        onInput() {
            if (this.updateTimeout) clearTimeout(this.updateTimeout)

            this.$emit('dirty', true)
            this.isDirty = true
            if (!this.autoCalculate) return
            this.updateTimeout = setTimeout(
                async () => await this.test(),
                recalculateDelay
            )
        },
        updateCalculation() {
            this.updatingCalculation = true
            this.loadCalculation()
            this.onInput()
        },
        updateArticles() {
            this.updatingArticles = true
            this.newVersionDate = formatISO(new Date())
            this.onInput()
        },
        async loadCalculation() {
            this.loading = true
            const version = this.updatingCalculation
                ? -2
                : this.part.calculationVersion
            const { result, ok } = await getCalculationById.call({
                args: {
                    calculationId: this.part.calculationId,
                    version: version,
                },
            })
            if (ok) {
                this.calculation = result
            }
            this.input = this.part.input || {}
            this.output = this.part.displayInfo
            this.loading = false
        },
        async test() {
            this.calculating = true
            const { result, ok } = await testCalculation.call({
                args: {
                    calculationId: this.calculation.calculationId,
                    version: this.part.calculationVersion,
                },
                payload: {
                    ...this.input,
                    $$project: this.projectId,
                    $$product: this.part.productId,
                    $$part: this.part.partId,
                    $$versionDate: this.newVersionDate || this.part.versionDate,
                },
            })

            if (ok) {
                this.output = result.state
                this.result = result
                this.calculating = false
                this.isDirty = false
                this.$emit('input', this.input)
                this.$emit('dirty', false)
            }
        },
        async save(callback) {
            const result = await sendCommand('CalculatePart', {
                projectId: this.projectId,
                productId: this.part.productId,
                partId: this.part.partId,
                input: this.input,
                updateCalculationVersion: this.updatingCalculation,
                versionDate:
                    this.newVersionDate ||
                    this.part.versionDate ||
                    formatISO(new Date()),
            })

            if (result.success) {
                this.$emit('saved')
            }

            callback({ success: result.success })
        },
        setInputArticle(key, article) {
            Vue.set(this.inputArticles, key, article)
        },
    },
}
</script>
<style scoped lang="css">
.v-alert {
    margin-bottom: 0;
}
</style>
