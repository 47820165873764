<template>
    <dialog-form
        :value="value"
        @input="$emit('input', $event)"
        :title="
            $localize('CalculatePart.title', { part: part.calculationName })
        "
        :allow-continue="false"
        @commit="save"
        max-width="900"
        :loading="loading"
    >
        <part-calculation
            v-model="part.input"
            :part="part"
            :project-id="projectId"
            :hide-buy-price="hideBuyPrice"
            :auto-calculate="autoCalculate"
            @dirty="onDirty"
            :recalculate="recalculate"
        />
        <template v-slot:actions>
            <v-btn
                class="mt-3 mr-3"
                @click="doRecalculate()"
                :loading="calculating"
                :disabled="!isDirty"
                >{{ $localize('Part.button.calculate') }}</v-btn
            >
        </template>
        <template v-slot:actions-right>
            <v-checkbox
                v-model="hideBuyPrice"
                class="mr-3"
                style="display: inline-block"
                :label="$localize('CalculationOutput.hideBuyPrice')"
                hide-details
            />
            <v-checkbox
                v-model="autoCalculate"
                style="display: inline-block"
                :label="$localize('CalculatePart.autoCalculate')"
                hide-details
            />
        </template>
    </dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import PartCalculation from '../components/PartCalculation'
import Vue from 'vue'

import { isGuid } from '@/services/validation'

import sendCommand from '@/services/sendCommand'

import { formatISO } from 'date-fns'

export default {
    components: {
        DialogForm,
        PartCalculation,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        part: {
            validator: Object,
            required: true,
        },
        projectId: {
            validator: isGuid,
            required: true,
        },
    },
    data() {
        return {
            recalculate: false,
            calculating: false,
            loading: false,
            updatingCalculation: false,
            updatingArticles: false,

            newVersionDate: null,

            hideBuyPrice: JSON.parse(
                localStorage.getItem('CalculationOutput:hideBuyPrice') || true
            ),
            autoCalculate:
                JSON.parse(
                    localStorage.getItem('CalculatePart:autoCalculate')
                ) || false,
            isDirty: true,
            updateTimeout: null,
        }
    },
    watch: {
        hideBuyPrice(value) {
            localStorage.setItem(
                'CalculationOutput:hideBuyPrice',
                JSON.stringify(value)
            )
        },
        autoCalculate(value) {
            localStorage.setItem(
                'CalculatePart:autoCalculate',
                JSON.stringify(value)
            )
        },
    },
    methods: {
        doRecalculate() {
            this.calculating = true
            this.recalculate = true
        },
        onDirty(isDirty) {
            this.isDirty = isDirty
            this.recalculate = false
            this.calculating = false
        },
        async save(callback) {
            const result = await sendCommand('CalculatePart', {
                projectId: this.projectId,
                productId: this.part.productId,
                partId: this.part.partId,
                input: this.part.input,
                updateCalculationVersion: this.updatingCalculation,
                versionDate:
                    this.newVersionDate ||
                    this.part.versionDate ||
                    formatISO(new Date()),
            })

            if (result.success) {
                this.$emit('saved')
            }

            callback({ success: result.success })
        },
        setInputArticle(key, article) {
            Vue.set(this.inputArticles, key, article)
        },
    },
}
</script>
<style scoped lang="css">
.v-alert {
    margin-bottom: 0;
}
</style>
