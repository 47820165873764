<template>
    <v-autocomplete
        :value="selectedArticleId"
        @input="onArticleSelected($event)"
        :label="label || $localize('SelectArticle.field.article')"
        :items="articles"
        item-text="text"
        item-value="articleId"
        :clearable="true"
        :rules="rules"
        :loading="loading"
        :prepend-icon="prependIcon"
        :autofocus="autofocus"
        :suffix="suffix"
        :hint="hint"
        :dense="dense"
    />
</template>

<script>
import { isGuid } from '@/services/validation'
import { getAllArticlesSlim } from '@/services/api'
import { required } from '@/services/validation'

export default {
    props: {
        value: {
            validator: isGuid,
        },
        label: String,
        autofocus: Boolean,
        articleCalculationId: {
            validator: isGuid,
            default: null,
        },
        articleCalculationIds: {
            type: Array,
            default: null,
        },
        required: {
            type: Boolean,
            default: true,
        },
        prependIcon: {
            type: String,
            default: 'mdi-toy-brick',
        },
        suffix: String,
        hint: String,
        dense: Boolean,
    },

    data() {
        return {
            articles: [],
            selectedArticleId: this.value,
            loading: false,
        }
    },
    computed: {
        rules() {
            if (this.required) {
                return [required]
            }
            return []
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.selectedArticleId = val
                this.emitArticle()
            },
        },
    },
    async created() {
        await this.refresh()
    },
    mounted() {
        this.emitArticle()
    },
    methods: {
        async refresh() {
            this.loading = true
            const { result, ok } = await getAllArticlesSlim.call({
                q: {
                    calculations: this.articleCalculationIds,
                    itemsPerPage: 10000,
                    includeArticleId: this.selectedArticleId,
                },
            })
            if (ok) {
                this.articles = result.items
            }

            for (let index = 0; index < this.articles.length; index++) {
                let article = this.articles[index]
                if (article.brand) {
                    article.text = article.brand + ' ' + article.name
                } else {
                    article.text = article.name
                }
            }

            if (!this.selectedArticleId) {
                const defaults = this.articles.filter((x) => x.isDefault)
                if (defaults.length > 0) {
                    this.onArticleSelected(defaults[0].articleId)
                }
            }

            this.emitArticle()
            this.loading = false
        },

        onArticleSelected(articleId) {
            this.selectedArticleId = articleId
            this.$emit('input', articleId)
            this.emitArticle()
        },
        emitArticle() {
            let article = this.articles.filter(
                (x) => x.articleId === this.selectedArticleId
            )
            if (article.length > 0) {
                this.$emit('update:article', article[0])
            } else {
                this.$emit('update:article', null)
            }
        },
    },
}
</script>
<style>
.v-list-item--dense .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle {
    font-size: 12px;
}
</style>
