<template>
    <v-row no-gutters align="stretch" class="mt-2">
        <v-col class="font-weight-bold product">{{ product.product }}</v-col>
        <v-col
            v-if="$vuetify.breakpoint.smAndUp"
            class="description font-weight-bold"
            >{{ product.description }}</v-col
        >
        <v-col
            v-if="$session.roles.includes('projects_calculate')"
            align="right"
            cols="auto"
            style="width: 100px"
            :class="productPriceClasses"
            class="pr-3 price"
            >{{ $format.currency(price) }}</v-col
        >
        <v-col cols="auto" :style="'width:' + buttonBarWidth">
            <v-toolbar
                v-if="$session.roles.includes('projects_calculate')"
                flat
                dense
                tile
                class
                style="background: none"
                :height="buttonBarHeight"
            >
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-menu>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" x-small>
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="calculation in calculations"
                                :key="calculation.calculationId"
                                @click="addPart(product, calculation)"
                            >
                                <v-list-item-title>{{
                                    calculation.name
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-toolbar-items>
                <collapsable-buttons :items="productButtons" :value="product" />
            </v-toolbar>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.xs && product.description" cols="12">
            {{ product.description }}
        </v-col>
        <enter-product-information
            v-if="enteringProductInformation"
            v-model="enteringProductInformation"
            :product-id="product.productId"
        />
        <delete-product
            v-if="deletingProduct"
            v-model="deletingProduct"
            :project-id="product.projectId"
            :product-id="product.productId"
        />

        <save-product
            v-if="savingProduct"
            v-model="savingProduct"
            :product-id="product.productId"
        />

        <copy-project
            v-if="copyingProject"
            v-model="copyingProject"
            :project-id="product.projectId"
            :product-id="copyingProjectProductId"
        />
        <v-lazy>
            <calculate-part
                v-if="calculatingPart"
                v-model="calculatingPart"
                :project-id="product.projectId"
                :part="calculatingPartObj"
            />
        </v-lazy>
    </v-row>
</template>

<script>
import EnterProductInformation from '../dialogs/EnterProductInformation'
import DeleteProduct from '../dialogs/DeleteProduct'
import SaveProduct from '../dialogs/SaveProduct'
import CopyProject from '../dialogs/CopyProject'
import CalculatePart from '../dialogs/CalculatePart'

import CollapsableButtons from '@/shared/components/CollapsableButtons'

import sendCommand from '@/services/sendCommand'
import { subscribe, unsubscribe } from '@/services/websocketHandler'
import Vue from 'vue'

export default {
    components: {
        EnterProductInformation,
        DeleteProduct,
        SaveProduct,
        CopyProject,
        CollapsableButtons,
        CalculatePart,
    },
    props: {
        value: Object,
        buttonBarWidth: String,
        buttonBarHeight: String,
        isFirst: Boolean,
        isLast: Boolean,
        calculations: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        product() {
            return this.value
        },
        productPriceClasses() {
            return {
                error: this.product.price !== null,
                success: !!this.product.discount && this.product.price === null,
                grey: true,
                'lighten-3': this.$settings.theme !== 'dark',
                'darken-3': this.$settings.theme === 'dark',
            }
        },
        price() {
            let price
            if (this.product.price !== null) {
                price = this.product.price
            } else {
                price = this.product.calculatedPrice + this.product.startingCost
                price = price * (1 - this.product.discount / 100)
            }
            return price
        },
    },
    data() {
        return {
            enteringProductInformation: false,
            deletingProduct: false,
            savingProduct: false,
            copyingProject: false,
            copyingProjectProductId: null,

            calculatingPart: false,
            calculatingPartObj: {},
            calculatingPartId: null,
            productButtons: [
                {
                    icon: 'mdi-content-save',
                    text: 'Opslaan',
                    if: () => this.$session.roles.includes('products_manage'),
                    action: (item) => {
                        this.savingProductId = item.productId
                        this.savingProduct = true
                    },
                },
                {
                    icon: 'mdi-content-cut',
                    text: this.$localize('ProductDetails.product.button.cut'),
                    action: (item) => this.copyProject(item),
                },
                {
                    icon: 'mdi-pencil',
                    text: this.$localize('ProductDetails.product.button.edit'),
                    action: (item) => this.enterProductInformation(item),
                },
                {
                    icon: 'mdi-content-copy',
                    text: this.$localize(
                        'ProductDetails.product.button.duplicate'
                    ),
                    action: (item) => this.duplicateProduct(item),
                },
                {
                    icon: 'mdi-arrow-up',
                    iconColor: 'success',
                    text: this.$localize(
                        'ProductDetails.product.button.moveUp'
                    ),
                    action: (item) => this.moveUp(item),
                    disabled: () => this.isFirst,
                },
                {
                    icon: 'mdi-arrow-down',
                    iconColor: 'success',
                    text: this.$localize(
                        'ProductDetails.product.button.moveDown'
                    ),
                    action: (item) => this.moveDown(item),
                    disabled: () => this.isLast,
                },
                {
                    icon: 'mdi-close',
                    text: this.$localize(
                        'ProductDetails.product.button.delete'
                    ),
                    action: (item) => this.deleteProduct(item),
                    textColor: 'error',
                },
            ],
            subscription: null,
        }
    },
    created() {
        this.subscription = subscribe(
            'ProductCalculated',
            this.onMessage,
            (x) => x.productId === this.product.productId
        )
    },
    destroyed() {
        unsubscribe(this.subscription)
    },
    methods: {
        onMessage(message) {
            const product = this.product
            Vue.set(product, 'startingCost', message.startingCost)
            Vue.set(product, 'calculatedPrice', message.calculatedPrice)
            this.$emit('input', product)
        },
        enterProductInformation(product) {
            this.enterProductInformationProductId = product.productId
            this.enteringProductInformation = true
        },
        deleteProduct(product) {
            this.deleteProductId = product.productId
            this.deletingProduct = true
        },
        copyProject(product) {
            this.copyingProjectProductId = product.productId
            this.copyingProject = true
        },

        async moveUp(product) {
            await sendCommand('MoveProductUp', {
                projectId: product.projectId,
                productId: product.productId,
            })
        },
        async moveDown(product) {
            await sendCommand('MoveProductDown', {
                projectId: product.projectId,
                productId: product.productId,
            })
        },

        async duplicateProduct(product) {
            await sendCommand('DuplicateProduct', {
                projectId: product.projectId,
                productToDuplicateId: product.productId,
            })
        },
        async addPart(product, calculation) {
            const result = await sendCommand('CreatePart', {
                projectId: product.projectId,
                productId: product.productId,
                calculationId: calculation.calculationId,
            })
            if (result.success) {
                this.calculatingPartObj = {
                    calculationId: calculation.calculationId,
                    calculationVersion: calculation.version,
                    partId: result.generatedId,
                    productId: product.productId,
                    state: {},
                    displayInfo: {},
                }
                this.calculatingPartId = result.generatedId
                this.calculatingPart = true
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 28px;

    span {
        display: inline-block;
        vertical-align: middle;
    }

    &.price {
        border-top: 1px solid #e0e0e0 !important;
    }

    &.product,
    &.description,
    &.price {
        border-top: 2px solid #e0e0e0 !important;
    }
    &.description {
        white-space: nowrap;
        overflow: hidden;
    }
}
</style>
