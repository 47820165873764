<template>
	<div>
		<input-number
            :dense="dense"
			:label="label"
			:value="val"
			@input="$emit('input', $event)"
			:required="inputField.isRequired"
            :suffix="inputField.suffix"
            :hint="inputField.hint"
		/>
	</div>
</template>

<script>
import InputNumber from '@/main/components/forms/InputNumber'
export default {
	components: { InputNumber },
	props: {
		label: String,
		value: [String, Number],
		inputField: Object,
        dense: Boolean
	},
	data() {
		return {
			val: this.value,
		}
	},
	watch: {
		value(val) {
			this.val = val
		},
	},
}
</script>
