<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('CreateDashboard.title')"
		@commit="save"
		:allow-continue="false"
	>
		<v-text-field
			v-model="createDashboard.name"
			:label="$localize('Dashboard.name')"
			:rules="[validation.required, validation.maxLength(20)]"
		/>

		<select-phases v-model="createDashboard.phases" />

		<select-project-statuses
			v-model="createDashboard.projectStatusIds"
			:label="$localize('Dashboard.projectStatuses')"
			:all-value="null"
            allow-all
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import SelectProjectStatuses from '@/main/components/forms/SelectProjectStatuses'
import SelectPhases from '@/main/components/forms/SelectPhases'

import sendCommand from '@/services/sendCommand'
import { required, maxLength } from '@/services/validation'
import PubSub from 'pubsub-js'

export default {
	components: { DialogForm, SelectProjectStatuses, SelectPhases },
	props: {
		value: Boolean,
	},
	data() {
		return {
			createDashboard: {
				phases: ['request', 'offer', 'order', 'invoice'],
			},
			validation: { required, maxLength },
		}
	},

	methods: {
		async save(callback) {
			const result = await sendCommand(
				'CreateDashboard',
				this.createDashboard
			)

			if (result.success) {
				this.$emit('saved')
				PubSub.publish('RefreshDashboardMenu')
			}
			callback({ success: result.success })
		},
	},
}
</script>
