<template>
    <dialog-form
        :value="value"
        @input="$emit('input', $event)"
        :title="product.product"
        :allow-continue="false"
        @commit="save"
        max-width="900"
        :loading="loading"
    >
        <template v-for="(part, index) in product.parts">
            <div :key="part.partId">
                <v-card-title>
                    {{
                        $localize('CalculatePart.title', {
                            part: product.parts[index].calculationName,
                        })
                    }}
                </v-card-title>

                <part-calculation
                    v-model="product.parts[index].input"
                    :part="product.parts[index]"
                    :project-id="projectId"
                    :hide-buy-price="hideBuyPrice"
                    :auto-calculate="autoCalculate"
                    :recalculate="recalculate[index]"
                    dense
                />
            </div>
        </template>
        <div style="text-align:center;">
        <v-menu>
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" x-small fab>
                    <v-icon small>mdi-plus</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    v-for="calculation in calculations"
                    :key="calculation.calculationId"
                    @click="addPart(product, calculation)"
                >
                    <v-list-item-title>{{
                        calculation.name
                    }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        </div>
        <template v-slot:actions>
            <v-btn
                class="mt-3 mr-3"
                @click="doRecalculate()"
                :loading="calculating"
                >{{ $localize('Part.button.calculate') }}</v-btn
            >
        </template>
        <template v-slot:actions-right>
            <v-checkbox
                v-model="hideBuyPrice"
                class="mr-3"
                style="display: inline-block"
                :label="$localize('CalculationOutput.hideBuyPrice')"
                hide-details
            />
            <v-checkbox
                v-model="autoCalculate"
                style="display: inline-block"
                :label="$localize('CalculatePart.autoCalculate')"
                hide-details
            />
        </template>
    </dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import PartCalculation from '../components/PartCalculation'
import Vue from 'vue'

import { isGuid } from '@/services/validation'

import sendCommand from '@/services/sendCommand'

import { formatISO } from 'date-fns'

export default {
    components: {
        DialogForm,
        PartCalculation,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        product: {
            validator: Object,
            required: true,
        },
        projectId: {
            validator: isGuid,
            required: true,
        },
        calculations: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            loading: false,
            updatingCalculation: false,
            recalculate: [],
            calculating: false,

            newVersionDate: null,

            hideBuyPrice: JSON.parse(
                localStorage.getItem('CalculationOutput:hideBuyPrice') || true
            ),
            autoCalculate:
                JSON.parse(
                    localStorage.getItem('CalculatePart:autoCalculate')
                ) || false,
            updateTimeout: null,
        }
    },
    watch: {
        hideBuyPrice(value) {
            localStorage.setItem(
                'CalculationOutput:hideBuyPrice',
                JSON.stringify(value)
            )
        },
        autoCalculate(value) {
            localStorage.setItem(
                'CalculatePart:autoCalculate',
                JSON.stringify(value)
            )
        },
    },
    methods: {
        doRecalculate() {
            for (let index in this.product.parts) {
                this.recalculate[index] = true
            }
        },
        async save(callback) {
            let failed = false
            for (let index in this.product.parts) {
                const part = this.product.parts[index]
                const result = await sendCommand('CalculatePart', {
                    projectId: this.projectId,
                    productId: part.productId,
                    partId: part.partId,
                    input: part.input,
                    updateCalculationVersion: this.updatingCalculation,
                    versionDate:
                        this.newVersionDate ||
                        part.versionDate ||
                        formatISO(new Date()),
                })

                if (!result.success) {
                    failed = true
                }
            }

            if (!failed) {
                this.$emit('saved')
            }
            callback({ success: !failed })
        },
        setInputArticle(key, article) {
            Vue.set(this.inputArticles, key, article)
        },
        async addPart(product, calculation) {
            const result = await sendCommand('CreatePart', {
                projectId: product.projectId,
                productId: product.productId,
                calculationId: calculation.calculationId,
            })
            if (result.success) {
                this.calculatingPartObj = {
                    calculationId: calculation.calculationId,
                    calculationVersion: calculation.version,
                    partId: result.generatedId,
                    productId: product.productId,
                    state: {},
                    displayInfo: {},
                }
                this.calculatingPartId = result.generatedId
                this.calculatingPart = true
            }
        },
    },
}
</script>
<style scoped lang="css">
.v-alert {
    margin-bottom: 0;
}
.v-card__text .v-card__title {
    padding-left: 0;
    padding-right: 0;
}
</style>
