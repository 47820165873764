<template>
    <v-row>
        <v-col>
            <v-select
                v-model="selectedProjectStatuses"
                :items="projectStatuses"
                :multiple="allowMultiple"
                :label="label || $localize('ProjectStatus.name')"
                item-text="name"
                item-value="projectStatusId"
                :loading="loading"
                @input="onInput"
                :disabled="allowAll && isAllSelected"
            />
        </v-col>
        <v-col cols="auto" class="d-flex align-center">
            <v-checkbox
                v-if="allowAll"
                :label="$localize('form.label.all')"
                v-model="isAllSelected"
                @change="onInput"
            />
        </v-col>
    </v-row>
</template>

<script>
import { getProjectStatuses } from '@/services/api'

export default {
    props: {
        value: {
            type: [Array, String],
        },
        label: String,
        allowAll: Boolean,
        allowMultiple: {
            type: Boolean,
            default: true,
        },
        allValue: String,
        phase: {
            type: String,
            default: null,
        },
    },
    watch: {
        value(val) {
            this.isAllSelected = val === this.allValue
            this.selectedProjectStatuses = this.createSelectionArray(val)
        },
        phase() {
            this.refresh()
        },
    },
    data() {
        return {
            isAllSelected: this.value === this.allValue,
            selectedProjectStatuses: this.createSelectionArray(this.value),
            projectStatuses: [],
            loading: false,
        }
    },
    created() {
        this.refresh()
    },
    methods: {
        async refresh() {
            this.loading = true
            const { result, ok } = await getProjectStatuses.call()
            if (ok) {
                if (this.phase) {
                    this.projectStatuses = result.filter(
                        (x) => x[this.phase] === true
                    )
                } else {
                    this.projectStatuses = result
                }
            }
            this.loading = false
        },
        createSelectionArray(val) {
            if (!val) {
                return []
            }
            if (Array.isArray(val)) {
                return val
            }
            if (this.allowMultiple) {
                return [val]
            }
            return val
        },
        onInput() {
            if (this.isAllSelected) {
                this.$emit('input', this.allValue)
            } else {
                this.$emit('input', this.selectedProjectStatuses)
            }
        },
    },
}
</script>
