<template>
    <dialog-form
        :value="value"
        @input="$emit('input', $event)"
        :title="$localize('CreateInvoice.title')"
        @commit="save"
        :allow-continue="false"
        :loading="loading"
        loading-skeleton="list-item-avatar-two-line"
        :action-text="$localize('CreateInvoice.button.createInvoice')"
    >
        <div v-if="project.isInvoiceLocked">
            <v-alert type="error">{{
                $localize('CreateInvoice.message.locked')
            }}</v-alert>
        </div>
        <div v-else-if="project.invoice.path">
            <v-alert type="warning">{{
                $localize('CreateInvoice.message.overwriteExisting')
            }}</v-alert>
        </div>
        <select-address
            v-model="selectedAddressId"
            :relation-id="project.relationId"
            default-type="$invoice"
            autofocus
        />
        <v-checkbox v-model="sendInvoice" label="Email versturen" />
        <send-email-form
            v-if="sendInvoice"
            v-model="emailData.email"
            :relation-id="emailData.relationId"
            :contact-id="emailData.contactId"
            @nav="nav"
        />
        <v-checkbox v-model="changeStatus" label="Status veranderen" />
        <select-project-statuses
            v-if="changeStatus"
            v-model="changeStatusId"
            :allow-all="false"
            :allow-multiple="false"
            phase="invoice"
        />
        <v-textarea
            v-if="changeStatus"
            v-model="note"
            :label="$localize('ChangeStatusStep.label.note')"
            :rules="[validation.maxLength(2000)]"
        ></v-textarea>
    </dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'
import { isGuid, maxLength } from '@/services/validation'
import SelectAddress from '@/main/components/forms/SelectAddress'
import SelectProjectStatuses from '@/main/components/forms/SelectProjectStatuses'
import { getProjectById, prepareInvoiceEmail } from '@/services/api'
import SendEmailForm from '../components/steps/SendEmailForm'
import Vue from 'vue'

export default {
    components: {
        DialogForm,
        SelectAddress,
        SendEmailForm,
        SelectProjectStatuses,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        projectId: {
            validator: isGuid,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            project: {
                invoice: {},
            },
            emailData: {},
            validation: { maxLength },
            selectedAddressId: null,
            changeStatusId: this.$settings.initialInvoiceStatus,
            sendInvoice: false,
            changeStatus: false,
            note: '',
        }
    },
    async created() {
        this.loading = true
        const { result, ok } = await getProjectById.call({
            args: { projectId: this.projectId },
        })
        if (ok) {
            this.project = result
            this.selectedAddressId = this.project.invoice.addressId
        }
        await this.loadInvoiceInfo()
        this.loading = false
    },
    methods: {
        async save(callback) {
            let command = {
                projectId: this.projectId,
                addressId: this.selectedAddressId,
                email: null,
                changeStatusId: null,
                note: null
            }
            if (this.sendInvoice) {
                command.email = this.emailData.email
            }

            if (this.changeStatus) {
                command.changeStatusId = this.changeStatusId
                command.note = this.note
            }

            const result = await sendCommand('CreateInvoice', command)

            if (result.success) {
                this.$emit('saved')
            }
            callback({ success: result.success })
        },
        async loadInvoiceInfo() {
            const { result, ok } = await prepareInvoiceEmail.call({
                args: { projectId: this.projectId },
            })
            if (ok) {
                this.emailData = result
            }
        },
        nav(to) {
            this.$emit('input', false)
            Vue.nextTick(() => {
                this.$router.push(to)
            })
        },
    },
}
</script>
